<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">

        <h5 class="ml-2 text-secondary font-weight-light"><span class="fa fa-file-alt"></span> Bon De Déplacement : <span class="ml-2 text-success"> {{nomBon}} </span> </h5>


        
        <div class="mt-1 ml-2 d-inline text-secondary font-weight-light">De : <span class="d-inline text-info"> {{Nsouc}} </span> Vers : <span class="d-inline text-info"> {{Ndestin}} </span> </div>

    </div>

    <div class="col">
         


                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                <b-button pill size="sm"  class="m-2 float-right" variant="danger" @click="del_BonClick">
                    <i class="fa fa-trash-alt"></i>
                    <span> Supprimer</span> 
                </b-button>

                <b-button v-if="d_gst_deplac_val==true" pill size="sm"  class="m-2 float-right" variant="success" @click="clickValiderBon">
                    <i class="fa fa-check"></i>
                    <span> Valider</span> 
                </b-button>

                <b-button v-if="d_gst_deplac_val==true" pill size="sm"  class="m-2 float-right" variant="info" @click="exportDox">
                    <i class="fa fa-print"></i>
                    <span> Imprimer</span> 
                </b-button>



    </div>

</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">
<div class="p-0">
        <b-button v-b-toggle.collap-categ.collap-articl size="lg" class=" m-0 pl-1 pr-1 float-left text-secondary" variant="link">
        <i class="fa fa-bars"></i>                
        </b-button> 
</div>
  
                <b-collapse id="collap-categ" visible  class="col-2 h-100 mr-3 ml-0 mt-2 p-0">

                            <div class="card border-success h-100 overflow-auto">
                            <div class="card-header  bg-success border-success p-1">
                                            <b-form-input v-model="searchCateg" class="border-info" id="search-cat" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                                           
                                            <b-button  @click="AfficherTousArts" size="sm"  class="ml-1 mb-1 mt-1 p-0 text-white text-decoration-none" variant="link">
                                            <i class="fa fa-list-alt mr-1"></i>  
                                            Tous Les Articles              
                                            </b-button> 
                            </div>

                            <b-list-group flush >
                            <b-list-group-item  v-for="item in filterCategorie" :key="item.id" button class="p-1" variant="success" @click="cat_click(item.id)">
                                    {{ item.nom }} 
                                    <p> <small>{{item.description}}</small> </p>
                            </b-list-group-item>

                            </b-list-group>
                            </div>



                    
                </b-collapse>

                <b-collapse id="collap-articl" visible  class="col-2 h-100 mr-0 mt-2 p-0">


                            <div class="card border-info h-100  overflow-auto">
                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchArt"  class="border-info" id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCodeBarreSearch"
                                                v-model="isCodeBarreSearch"
                                                name="isCodeBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCodeBarreSearch">Code Barre</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                       
                            <b-list-group-item  v-for="item in filterArticle" :key="item.id" button class="p-1" variant="info" @click="art_click(item.id)">
                                 {{ item.ref }}
                                 <p> <small>{{item.description}}</small> </p>
                                 </b-list-group-item>

                          

                            </b-list-group>
                            </div>






                </b-collapse>

                <div class="col h-100 overflow-auto mt-2 mr-4 ml-1">


            <form @submit.prevent="handleSubmit" >
            <div class="row">
            <div class="form-group col">
                <label for="ref" class="form-control-sm mb-0">Référence</label>
                <input type="text" v-model="itemForm.ref" id="ref" name="ref" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.ref.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col">
                <label for="code_bar" class="form-control-sm mb-0">Code Barre</label>
                <input type="text" v-model="itemForm.code_bar" id="code_bar" name="code_bar" class="form-control form-control-sm"/>
            </div>

            </div>




            <div class="row ">
            <div class="form-group col">

                <label for="lot_sour_id" class="form-control-sm mb-0">Lot Source</label>
                <select class="form-control form-control-sm" id="lot_sour_id" v-model="itemForm.lot_sour_id"
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.lot_sour_id.$error }"  @change="SetCurrentLotSourc">
                <option v-for="item in ListLotsSourc" :key="item.id" v-bind:value="item.id">   
                {{ item.nom }} - {{ item.qte }}
                </option>
                </select>
                <div v-if="isSubmitted && !$v.itemForm.lot_sour_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


            </div>

            <div class="form-group col">

                <label for="lot_dest_id" class="form-control-sm mb-0">Lot Destination</label>
                
                <b-button v-if="CurrentLotSourc.id" @click="AddNewLots" size="sm"  class="ml-1 mr-1 float-left p-1 text-decoration-none" variant="link">
                <i class="fa fa-plus-square"></i>                
                </b-button> 

                <select class="form-control form-control-sm" id="lot_dest_id" v-model="itemForm.lot_dest_id"
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.lot_dest_id.$error }">
                <option v-for="item in ListLotsDestin" :key="item.id" v-bind:value="item.id">   
                {{ item.nom }} - {{ item.qte }}
                </option>
                </select>
                <div v-if="isSubmitted && !$v.itemForm.lot_dest_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


            </div>
            </div>







            <div class="row">
                


            <div class="form-group col">
                <label for="qte_min" class="form-control-sm mb-0">Quantité <span v-if="CurrentLotSourc.qte">(max : {{CurrentLotSourc.qte}})</span> </label>
                <input type="text" v-model="itemForm.qte" id="qte_min" name="qte_min" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>

                            
                                        <div class="form-group col">
                                            <label for="unite" class="form-control-sm mb-0">Unité :</label>
                                            <input type="text" v-model="itemForm.unite" id="unite" name="unite" class="form-control form-control-sm"/>
                                        </div>

            </div>

            
            <div class="form-group">
            <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemForm.description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-secondary btn-block">Ajouter Article/Produit</button>
            </div>

            </form>


            

                      <b-table 
        sort-icon-left
        small 
        responsive 
        :fields="fieldsArticle" 
        :items="ArticlesList"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
            </template>
        
        </b-table>


         

</div>

  </div>

      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
 import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';

export default {
 props: ['idBon','nomBon','idDepS','idDepD','Nsouc','Ndestin','ddate'],

 
    computed: {
                entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    d_gst_deplac_val() {
      return this.$store.getters.get_gst_deplac_val;
    },

    filterArticle() {
            return this.listArt.filter(article => {

            if(this.isCodeBarreSearch)
            {
                if(article.code_bar)
                {
                return article.code_bar.toLowerCase().includes(this.serchArt.toLowerCase())
                }

            }
            else
            {
                return article.ref.toLowerCase().includes(this.serchArt.toLowerCase())
            }

      })
    },
    filterCategorie() {
            return this.listCat.filter(category => {
            return category.nom.toLowerCase().includes(this.searchCateg.toLowerCase())   
      })
    }
    },
    mounted(){

            if(this.idBon)
            {
            this.fetchCategorieList()
            this.fetchListArts(this.idBon)
            }
            else
            {
            this.flashMessage.show({
                    status: 'error',
                                            title: 'Erreur de Chargement',
                                            message: 'Impossible charger les données de ce bon'
            })
            }

    },
    data () {
		return {
            fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'unite', label: 'Unité'},
                {key: 'lot_s', label: 'Lot Source'},
                {key: 'lot_d', label: 'Lot Destination'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],

            isCodeBarreSearch: false,
            CurrentLotSourc:'',
            searchCateg:'',
            serchArt:'',
            ArticlesList:[],
            listCat: [],
            listArt: [],
            ListLotsSourc:[],
            ListLotsDestin:[],
            itemForm: {
                    id:'',
                    ref:'',
                    code_bar:'',
                    description:'',
                    qte:'',
                    unite:'',
                    lot_sour_id:'',
                    lot_dest_id:'',
                    article_id:''},
            isSubmitted: false,
            isLoading: false
		}
    },
    validations: {
            itemForm: {
                ref: {
                    required
                },
                qte: {
                    required,
                    decimal
                },
                article_id: {
                    required
                },
                lot_sour_id: {
                    required
                },
                lot_dest_id: {
                    required
                }
            }

    },
    methods:{


        exportDox()
{

let datrw =[new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 3500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                            new TextRun({
                                            text: "Référence",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        spacing: {
                                        before: 100,
                                        after: 100
                                    },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                            new TextRun({
                                            text: "Quantité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                            new TextRun({
                                            text: "Unité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),
                                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                  
                                  children: [
                                            new TextRun({
                                            text: "Lot Source",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                 
                                  children: [
                                            new TextRun({
                                            text: "Lot Destination",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

            ],
        })]

this.ArticlesList.forEach(function(item){

let r = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 3500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                  spacing: {
                                            before: 100,
                                            after: 100,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                        new TextRun({
                                            text: item.qte.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],

                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),
                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                               
                        children: [
                                            new TextRun({
                                            text: item.unite,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],

                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),
                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                                                                                                          
                                    children: [
                                        new TextRun({
                                            text: item.lot_s,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                    spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                                                                                                   
                                    children: [
                                        new TextRun({
                                            text: item.lot_d,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],                            spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

            ],
        })

  datrw.push(
 r
)
});


const table = new Table({

    rows:  datrw
})

const doc = new Document({

    sections: [
        {



            properties: {
                page: {
                    pageNumbers: {
                        start: 1,
                        formatType: PageNumberFormat.DECIMAL,
                    },
                    margin:{
                                left:500,
                                right:500
                            }
                },
            },
                    headers: {
                        default: new Header({
                            children: [
                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.entet_pag,
                                color: '000000',
                                bold: true,

                                }),
                                    ],
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_1,
                                    alignment: AlignmentType.CENTER,
                                }),




                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.pied_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                top: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),

                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                            color: '000000'
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                            color: '000000'
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },




            children: [

                new Paragraph({



                    children: [

                        new TextRun({
                        text: "Bon De Déplacement",
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),
                    ],

                    heading: HeadingLevel.HEADING_1,
                                        alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 200,
                            before: 200
                        },
                }),




                new Paragraph({



                    children: [

                        new TextRun({
                        text: "N° : "+this.nomBon,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: "          De : "+this.Nsouc+" Au "+this.Ndestin,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    })
                    ],

                    heading: HeadingLevel.HEADING_3,
                    alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 100,
                            before: 100
                        },
                }),
                
                

                new Paragraph({



                    children: [
                        new TextRun({
                        text: "De la part de : ",
                        color: '030633',
                        bold: false,
                        font: "Segoe UI"
                    }),
                        new TextRun({
                        text: this.user_name,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),
                    new TextRun({
                        text: "          Date : ",
                        color: '030633',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: this.ddate,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    })
                    ],

                    heading: HeadingLevel.HEADING_3,
                    alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 300,
                            before: 100
                        },
                }),

                table
                
                ]


        }]

});





    Packer.toBlob(doc).then(blob => {

  saveAs(blob, "Deplac.docx");
    });

},

        fetchCategorieList: function () {
                    this.isLoading=true;
                    this.$http.get('/categories')
                    .then((result) => {
                                this.listCat = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        cat_click(id_cat)
        {
                    this.isLoading=true;
                    this.$http.get('/articlesCat/'+id_cat)
                    .then((result) => {
                                this.listArt = result.data.data

                                
                                this.isLoading=false;


                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        art_click(id_art)
        {

            let art = this.listArt.findIndex(item => item.id === id_art)
            this.itemForm.ref=this.listArt[art].ref
            this.itemForm.code_bar=this.listArt[art].code_bar
            this.itemForm.description=this.listArt[art].description
            this.itemForm.article_id=this.listArt[art].id
             this.itemForm.unite = this.listArt[art].unite

            this.fetchSourcLots(id_art)
            this.fetchDestinLots(id_art)
        },
        fetchSourcLots(id_art) {


                    this.isLoading=true;
                    this.$http.get('/lotsArtDepQte/'+id_art+'/'+this.idDepS)
                    .then((result) => {
                                this.ListLotsSourc = result.data.data
                                this.isLoading=false;


                                if(this.ListLotsSourc.length>0)
                                {
                                        this.itemForm.lot_sour_id=this.ListLotsSourc[0].id
                                        this.itemForm.qte=this.ListLotsSourc[0].qte


                                        this.CurrentLotSourc = this.ListLotsSourc[0]
                                }
                                else
                                {
                                        this.itemForm.lot_sour_id=''
                                        this.CurrentLotSourc=''
                                }
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

            //lot desti


        },
        fetchDestinLots(id_art)
        {
                    this.isLoading=true;
                    this.$http.get('/lotsArtDep/'+id_art+'/'+this.idDepD)
                    .then((result) => {
                                this.ListLotsDestin = result.data.data
                                this.isLoading=false;


                                if(this.ListLotsDestin.length>0)
                                {
                                        this.itemForm.lot_dest_id=this.ListLotsDestin[0].id

                                }
                                else
                                {
                                        this.itemForm.lot_dest_id=''
                                }
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        fetchListArts(id_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailbon/'+id_bn)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        handleSubmit(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {
                        if(this.itemForm.lot_sour_id==this.itemForm.lot_dest_id)
                        {
                                                this.flashMessage.show({
                                                status: 'info',
                                                title: 'Vérifiez les Lots Source/Destination !!',
                                                })
                        }
                        else
                        {
                            this.isLoading=true;

                            this.$http.post('/bnDetailDeplacs',{
                            ref:this.itemForm.ref,
                            description:this.itemForm.description,
                            qte:this.itemForm.qte,
                            unite:this.itemForm.unite,
                            article_id:this.itemForm.article_id,
                            lot_sour_id:this.itemForm.lot_sour_id,
                            lot_dest_id:this.itemForm.lot_dest_id,
                            bn_deplac_id: this.idBon

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.ArticlesList.unshift(response.data.data);

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                            
                        }            

                }

        },
        del_click(itemA){

            this.isLoading=true;

            this.$http.delete('/bnDetailDeplacs/'+itemA.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.ArticlesList.findIndex(item => item.id === itemA.id)

                    this.ArticlesList.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        SetCurrentLotSourc()
        { 
            let am = this.ListLotsSourc.findIndex(item => item.id === this.itemForm.lot_sour_id)


            this.CurrentLotSourc = this.ListLotsSourc[am]
            this.itemForm.qte= this.ListLotsSourc[am].qte


        },
        AddNewLots()
        {

            this.isLoading=true;

            this.$http.post('/lots',{
                   
                    nom:this.CurrentLotSourc.nom,
                    qte:0,
                    date: this.CurrentLotSourc.date,
                    date_exp:this.CurrentLotSourc.date_exp,
                    depot_id:this.idDepD,
                    article_id:this.CurrentLotSourc.article_id,
                    prix_achat: this.CurrentLotSourc.prix_achat


                })
                .then(response => {

                    this.isLoading=false;

                    this.ListLotsDestin.unshift(response.data.data);

                     this.itemForm.lot_dest_id=response.data.data.id

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuDeplac'
                    });
        },
        del_BonClick() {

                                this.$confirm("Voulez-vous vraiment supprimer ce Bon??")
                                .then(() => {

                                    this.BnDell(this.idBon)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },
        BnDell(id_bn){

            this.isLoading=true;

            this.$http.delete('/bnDeplac/'+id_bn)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })

                //goto first page:
                    this.$router.push({ 
                        name: 'BonDeplac'

                    });




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        clickValiderBon()
        {
        
                                this.$confirm("Voulez-vous vraiment VALIDER ce Bon??")
                                .then(() => {

                                    this.validerBon(this.idBon)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });

        },
        validerBon(id_bn)
    {

            this.isLoading=true;

            this.$http.get('/validerDeplacs/'+id_bn+'/1')
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon valider',
                message: 'Les données sont validées correctement'
                })

                //goto first page:
                    this.$router.push({ 
                        name: 'BonDeplac'

                    });




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        AfficherTousArts()
        {
        this.cat_click(0)
        }
},
components: {
              Loading      
}


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>
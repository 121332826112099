<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center var_color">
 <i class="fas fa-cubes fa-4x"></i>
</div>

<div class=" d-flex justify-content-center var_color mb-4">
 <h2 class="font-weight-lighter">Gestion du stock</h2>
</div>

<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col" v-show="d_gst_depo">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_depot">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Gestion Des Dépôts</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-layer-group fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Annual) Card Example -->
            <div class="col" v-show="d_gst_cat">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_categorie">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Gestion Des Catégories</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-sticky-note fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>


<div class=" row p-4" v-show="d_gst_art">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_article">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Gestion Des Articles</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-bars fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>


<div class=" row p-4" v-show="d_gst_barcode">

<!-- Earnings (Monthly) Card Example -->
<div class="col">
  <div class="btn card border border-light shadow h-100 py-2" @click="click_article_bar">
    <div class="card-body">
      <div class="row no-gutters align-items-center">
        <div class="col mr-2">
          <div class="text-xs font-weight-bold text-secondary text-uppercase">Codes-Barre</div>
        </div>
        <div class="col-auto">
          <i class="fas fa-barcode fa-2x text-secondary"></i>
        </div>
      </div>
    </div>
  </div>
</div>


</div>




  </div>
</template>

<script>
export default {
computed:{
    d_gst_depo() {
      return this.$store.getters.get_gst_depo;
    },
    d_gst_cat() {
      return this.$store.getters.get_gst_cat;
    },
    d_gst_art() {
      return this.$store.getters.get_gst_art;
    },
    d_gst_barcode() {
      return this.$store.getters.get_gst_barcode;
    },

    
},
 methods:{
      click_depot()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'DepotsList'

                    });
      },
      click_categorie()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'CategoriesList'

                    });
      },
      click_article()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'ArticleList'

                    });
      },
      click_article_bar()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'barreCodeArticleListe'

                    });
      },

      

 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.var_color{
  color: #35b5e4;
}

.text_org{
  color: green;
}
</style>
import { render, staticRenderFns } from "./MenuEntrees.vue?vue&type=template&id=58749969&scoped=true&"
import script from "./MenuEntrees.vue?vue&type=script&lang=js&"
export * from "./MenuEntrees.vue?vue&type=script&lang=js&"
import style0 from "./MenuEntrees.vue?vue&type=style&index=0&id=58749969&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "58749969",
  null
  
)

export default component.exports
<template>
    <div>
      
                            
    
              <div class="rounded-lg shadow border border-white bg-white">
    
                <div class="row justify-content-centre align-items-center">    
                
                <div class="col">
                <h4 class="m-3 font-weight-lighter var_color float-left"> <span class="fa fa-bars"></span> Articles (Codes Barres)   </h4> 

                </div>
                
    
    
    
                <div class="col">
                    <div class="input-group col">
                                        <select class="form-control border border-ligth " id="selected_centre" v-model="selectedCategorie">
                                                <option v-for="item in categories" :key="item.id" v-bind:value="item.id">   
                                                    {{ item.nom }}
                                                </option>
                                                <option value=0>Tous</option>
    
                                         </select>
    
    
                                        <div class="input-group-prepend">
                                            <button @click="click_ArticlList" class="btn  btn-light  border border-ligth rounded-right" type="submit" id="button-addon1">   
                                            <span class="fa fa-search"></span> Liste
                                            </button>
                                        </div>
    
                    </div>
                </div>
    
                 <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher" class="col-3 mr-0"></b-form-input>
     
                                        <b-button pill size="sm"  class="col-1 mt-0 mr-1 float-right text-secondary" variant="link" @click="NewBon">
                                                    <i class="fa fa-home fa-2x"></i>
                                        </b-button>
    
                </div>
           
    
    
    
            <b-table 
            id="main-table"
            responsive  
            sort-icon-left
            head-variant="light"
            :filter="filter"
            :fields="fields" 
            :items="itemslist"
            :per-page="perPage"
            :current-page="currentPage"
            >
                <template #cell(actions)="row">

                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="stock_click(row.item, $event.target)">
                                <i class="fa fa-barcode"></i> 
                    </b-button>
                </template>
            
            </b-table>
    
                            <b-pagination
                    pills
                    v-model="currentPage"
                    :total-rows="rows_pagin"
                    :per-page="perPage"
                    aria-controls="main-table"
                    align="center"
                    ></b-pagination>
          </div>
    

    

          <comoscodebare v-bind:nom_article="printCodBarrPars.nom_article"
                :code_barre="printCodBarrPars.code_barre"
                :prix="printCodBarrPars.prix"


        ></comoscodebare>
    
        <!-- les trucs public -->
    
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
    

            </div>
            </template>
    
    <script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import comoscodebare from '@/components/Stock/CodeBarrePrint.vue';

    
    export default {
        computed: {
          
          rows_pagin() {
            return this.itemslist.length
        }
        },
        mounted(){
            //this.fetchList()
    
            this.fetchFormCategorisDepots()
        },
        data () {
            return {
                selectedCategorie:'',
                filter: null,
                perPage: 10,
                currentPage: 1,
                categories:[],
                itemslist: [],

                fields: [
                    {key: 'ref', label: 'Réf', sortable: true},
                    {key: 'code_bar', label: 'Code Barre', sortable: true},
                    {key: 'prix_vent', label: 'Prix Vente', sortable: true},
                    {key: 'description', label: 'Description'},
                    { key: 'actions', label: '' }
                ],


                printCodBarrPars: {
                    nom_article:'',
                    code_barre:'',
                    prix:''},
                //public trucs

                isLoading: false,
    
            }
        },

        methods :{
    


            stock_click(item) {

            this.printCodBarrPars.nom_article=item.ref
            this.printCodBarrPars.code_barre=item.code_bar
            this.printCodBarrPars.prix=item.prix_vent



            this.$root.$emit('bv::show::modal', 'barcodemodal' )

            },



            click_ArticlList()
            {
                        if(this.selectedCategorie)
                            {
    
                        this.isLoading=true;
                        this.$http.get('/articlesList/'+this.selectedCategorie)
                        .then((result) => {
                                    this.itemslist = result.data.data
                                    this.isLoading=false;
    
                                    
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });
    
                            }
                            else
                            {
                                            this.flashMessage.show({
                                            status: 'info',
                                            title: 'Sélectionez une Catégorie et Reésayez !!',
                                            })
                            }
            },

            fetchFormCategorisDepots: function () {
                        //chager les categories:
                        this.isLoading=true;
                        this.$http.get('/categories')
                        .then((result) => {
                                    this.categories = result.data.data
                                    this.isLoading=false;
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });
            },
    
            NewBon()
            {
                        this.$router.push({ 
                            name: 'menuStock'
                        });
            },
    
        },
        components: {
                  Loading,
                  comoscodebare  
        }
    }
    </script>
    
    <style  scoped>
    .var_color{
      color: #35b5e4;
    }
    </style>
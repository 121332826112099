<template>
  

<div  class="card rounded-lg shadow border border-white bg-white h-100 mb-0 pb-3 overflow-auto" >
    <div class="card-header bg-transparent">
             
           

<div class="row">


    <div class="col">
        
        <h5 class="ml-2 text-secondary font-weight-light"> <span class="fa fa-file-alt"></span> Bon D'Entrées : <span class="ml-2 text-success"> {{nomBon}} </span> </h5>
        
        <div class="mt-1 ml-2 d-inline text-secondary font-weight-light">Dépôt : <span class="d-inline text-info"> {{nDep}} </span>  </div>
        




    </div>

    <div class="col">
         
                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>


                <b-button pill size="sm"  class="m-2 float-right" variant="danger" @click="del_BonClick">
                    <i class="fa fa-trash-alt"></i>
                    <span> Supprimer</span> 
                </b-button>

                <b-button v-if="d_gst_entre_val==true" pill size="sm"  class="m-2 float-right" variant="success" @click="clickValiderBon">
                    <i class="fa fa-check"></i>
                    <span> Valider</span> 
                </b-button>

                <b-button v-if="d_gst_entre_val==true" pill size="sm"  class="m-2 float-right" variant="info" @click="exportDox">
                    <i class="fa fa-print"></i>
                    <span> Imprimer</span> 
                </b-button>



    </div>

</div>


    </div>




    <div class="row card-body h-100 p-0 m-0">
<div class="p-0">
        <b-button v-b-toggle.collap-categ.collap-articl size="lg" class=" ml-0 mr-0 pl-1 pr-1 float-left text-secondary" variant="link">
        <i class="fa fa-bars"></i>                
        </b-button> 
</div>
  
                <b-collapse id="collap-categ" visible  class="col-2 h-100 mr-3 ml-0 mt-2 p-0">

                            <div class="card border-success h-100 overflow-auto">
                            <div class="card-header  bg-success border-success p-1">
                                            <b-form-input v-model="searchCateg" class="border-info" id="search-cat" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                                           
                                            <b-button  @click="AfficherTousArts" size="sm"  class="ml-1 mb-1 mt-1 p-0 text-white text-decoration-none" variant="link">
                                            <i class="fa fa-list-alt mr-1"></i>  
                                            Tous Les Articles              
                                            </b-button> 
                            </div>

                            <b-list-group flush >
                            <b-list-group-item  v-for="item in filterCategorie" :key="item.id" button class="p-1" variant="success" @click="cat_click(item.id)">
                                    {{ item.nom }} 
                                    <p> <small>{{item.description}}</small> </p>
                            </b-list-group-item>

                            </b-list-group>
                            </div>



                    
                </b-collapse>

                <b-collapse id="collap-articl" visible  class="col-2 h-100 mr-0 mt-2 p-0">


                            <div class="card border-info h-100  overflow-auto">
                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchArt"  class="border-info" id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCodeBarreSearch"
                                                v-model="isCodeBarreSearch"
                                                name="isCodeBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCodeBarreSearch">Code Barre</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                       
                            <b-list-group-item  v-for="item in filterArticle" :key="item.id" button class="p-1" variant="info" @click="art_click(item.id)">
                                 {{ item.ref }}
                                 <p> <small>{{item.description}}</small> </p>
                                 </b-list-group-item>

                          

                            </b-list-group>
                            </div>






                </b-collapse>

                <div class="col h-100 overflow-auto mt-2 mr-4 ml-1">


            <form @submit.prevent="handleSubmit" >
            <div class="row">
            <div class="form-group col">
                <label for="ref" class="form-control-sm mb-0">Référence</label>
                <input type="text" v-model="itemForm.ref" id="ref" name="ref" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.ref.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.ref.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col">

                <label for="lot_id" class="form-control-sm mb-0">Lot :</label>

                <b-button @click="addLot_click" v-if="itemForm.article_id"  size="sm"  class="ml-1 mr-1 float-left p-1 text-decoration-none" variant="link">
                <i class="fa fa-plus-square"></i>                
                </b-button> 

                <select class="form-control form-control-sm" id="lot_id" v-model="itemForm.lot_id"
                :class="{ 'is-invalid': isSubmitted && $v.itemForm.lot_id.$error }">
                <option v-for="item in ListLots" :key="item.id" v-bind:value="item.id">   
                {{ item.nom }} - {{ item.qte }}
                </option>
                </select>
                <div v-if="isSubmitted && !$v.itemForm.lot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


            </div>

            </div>

            <div class="row"> 

            <div class="form-group col">
                <label for="qte_min" class="form-control-sm mb-0">Quantité : </label>
                <input type="text" v-model="itemForm.qte" id="qte_min" name="qte_min" class="form-control form-control-sm"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.qte.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.qte.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                <div v-if="isSubmitted && !$v.itemForm.qte.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
            </div>
                                        <div class="form-group col">
                                            <label for="unite" class="form-control-sm mb-0">Unité :</label>
                                            <input type="text" v-model="itemForm.unite" id="unite" name="unite" class="form-control form-control-sm"/>
                                        </div>

            </div>
          
            <div class="form-group">
            <textarea name="description" id="description"  rows="2"  placeholder="Description ..." class="form-control form-control-sm" v-model="itemForm.description"></textarea>
            </div>

            <div class="form-group">
                <button type="submit" class="btn btn-secondary btn-block">Ajouter Article/Produit</button>
            </div>

            </form>


            

                      <b-table 
        sort-icon-left
        small 
        responsive 

        :fields="fieldsArticle" 
        :items="ArticlesList"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
            </template>
        
        </b-table>


         

</div>

  </div>




    
 <b-modal  id="lolmodal" title="Nouveau Lot"  size="m" hide-footer>


           <form @submit.prevent="handleSubmitLot()">

            <div class="row">

            <div class="form-group col">
            <b-button   v-if="itemForm.is_lot_manag"  class="ml-2 float-left p-0" variant="link" @click="changeLot">
                <i class="fa fa-retweet"></i>
                <span> </span> 
            </b-button>
                <label for="nom">Nom</label>
                <input type="text" v-model="itemFormLot.nom" id="nom" name="nom" class="form-control form-control-sm" />
               
            </div>



            <div class="form-group col">

            <label for="prix_achat">Prix D'Achat :</label>
                <input type="text" v-model="itemFormLot.prix_achat" id="prix_achat" name="prix_achat" class="form-control form-control-sm" />
            </div>


            </div>

            <div v-if="itemForm.is_lot_manag" class="row">

            <div class="form-group col">
                <label for="date_lot" class="mr-sm-2">Date</label>
                    <b-form-datepicker
                    class="mr-sm-2  form-control-sm"
                    id="datepicker-date_lot"
                    placeholder="date"
                    v-model="itemFormLot.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>

            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date Exp:</label>
                    <b-form-datepicker
                    class="mr-sm-2 form-control-sm"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemFormLot.date_exp"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>




            </div>



            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Ajouter</button>
            </div>

        </form>

</b-modal>






      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
 import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
 import { saveAs } from 'file-saver';

export default {
 props: ['idBon','nomBon','idDep','nDep','ddate'],

 
    computed: {
                entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
            user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
     d_gst_entre_val() {
      return this.$store.getters.get_gst_entre_val;
    },
    filterArticle() {
            return this.listArt.filter(article => {

            if(this.isCodeBarreSearch)
            {
                if(article.code_bar)
                {
                return article.code_bar.toLowerCase().includes(this.serchArt.toLowerCase())
                }

            }
            else
            {
                return article.ref.toLowerCase().includes(this.serchArt.toLowerCase())
            }

      })
    },
    filterCategorie() {
            return this.listCat.filter(category => {
            return category.nom.toLowerCase().includes(this.searchCateg.toLowerCase())   
      })
    }
    },
    mounted(){

            if(this.idBon)
            {
            this.fetchCategorieList()
            this.fetchListArts(this.idBon)
            }
            else
            {
            this.flashMessage.show({
                                            status: 'error',
                                            title: 'Erreur de Chargement',
                                            message: 'Impossible charger les données de ce bon'
            })
            }

    },
    data () {
		return {
            fieldsArticle: [
                {key: 'ref', label: 'Réf'},
                {key: 'qte', label: 'Quantité'},
                {key: 'unite', label: 'Unité'},
                {key: 'nom_lot', label: 'Lot'},
                {key: 'description', label: 'Description'},
                { key: 'actions', label: '' }
            ],

            isCodeBarreSearch: false,
            searchCateg:'',
            serchArt:'',
            ArticlesList:[],
            listCat: [],
            listArt: [],
            itemForm: {
                    id:'',
                    ref:'',
                    code_bar:'',
                    description:'',
                    qte:'',
                    prix_achat:0,
                    unite:'',
                    nom_lot:'',
                    lot_id:'',
                    article_id:'',
                    is_lot_manag:''},
            itemFormLot: {
                    nom:'',
                    qte:'',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    date_exp:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    prix_achat:0,
                    depot_id:'',
                    article_id:''},
            ListLots:[],
            isSubmitted: false,
            isLoading: false
		}
    },
    validations: {
            itemForm: {
                ref: {
                    required
                },
                qte: {
                    required,
                    decimal
                },
                article_id: {
                    required
                },
                nom_lot: {
                    required,
                },
                lot_id: {
                    required,
                }
            }

    },
    methods:{

        exportDox()
{

let datrw =[new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                            new TextRun({
                                            text: "Référence",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                        spacing: {
                                        before: 100,
                                        after: 100
                                    },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 6000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                            new TextRun({
                                            text: "Description",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                            new TextRun({
                                            text: "Quantité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                            new TextRun({
                                            text: "Unité",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                            new TextRun({
                                            text: "Lot",
                                            color: '0f31b5',
                                            bold: false,
                                            font: "Segoe UI"
                                            }),
                                        ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),



            ],
        })]

this.ArticlesList.forEach(function(item){

let r = new TableRow({
            children: [
                new TableCell({
                    width: {
                        size: 4000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                        new TextRun({
                                            text: item.ref,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                  spacing: {
                                            before: 100,
                                            after: 100,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 6000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                        new TextRun({
                                            text: item.description,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                        spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                        new TextRun({
                                            text: item.qte.toString(),
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),

                
                 new TableCell({
                    width: {
                        size: 2500,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                        children: [
                                            new TextRun({
                                            text: item.unite,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],

                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


                new TableCell({
                    width: {
                        size: 3000,
                        type: WidthType.DXA,
                    },
                    verticalAlign: VerticalAlign.CENTER,
                    children: [new Paragraph({
                                 
                                  children: [
                                            new TextRun({
                                            text: item.nom_lot,
                                            color: '000000',
                                            bold: true,
                                            }),
                                                ],                                                      spacing: {
                                            before: 10,
                                            after: 10,
                                            left: 10,
                                            right:10
                                        },
                                  heading: HeadingLevel.HEADING_3,
                                  alignment: AlignmentType.CENTER,
                              })],
                }),


            ],
        })

  datrw.push(
 r
)
});



const table = new Table({

    rows:  datrw
})


const doc = new Document({

    sections: [
        {




            properties: {
                page: {
                    pageNumbers: {
                        start: 1,
                        formatType: PageNumberFormat.DECIMAL,
                    },
                    margin:{
                                left:500,
                                right:500
                            }
                },
            },
                    headers: {
                        default: new Header({
                            children: [
                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.entet_pag,
                                color: '000000',
                                bold: true,

                                }),
                                    ],
                                    heading: HeadingLevel.TITLE,
                                    alignment: AlignmentType.CENTER,
                                }),

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.sousentet_pag,
                                color: '000000',
                                bold: false,
                                font: "Segoe UI"
                                })

                                    ],
                                border: {
                                bottom: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_1,
                                    alignment: AlignmentType.CENTER,
                                }),




                            ],
                        }),
                    },
                    footers: {
                        default: new Footer({
                            children: [

                        new Paragraph({
                                    children: [
                            new TextRun({
                                text: this.pied_pag,
                                color: '000000',
                                bold: false,
                                })

                                    ],
                                border: {
                                top: {
                                        color: "000000",
                                        space: 4,
                                        value: "single",
                                        size: 10,
                                    }},
                                    heading: HeadingLevel.HEADING_3,
                                    alignment: AlignmentType.CENTER,
                                }),

                                new Paragraph({
                                    alignment: AlignmentType.RIGHT,
                                    children: [
                                        new TextRun({
                                            children: ["Page : ", PageNumber.CURRENT],
                                            color: '000000'
                                        }),
                                        new TextRun({
                                            children: [" Sur ", PageNumber.TOTAL_PAGES],
                                            color: '000000'
                                        }),
                                    ],
                                }),
                            ],
                        }),
                    },



            children: [

                new Paragraph({



                    children: [

                        new TextRun({
                        text: "Bon D'Entrées",
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),
                    ],

                    heading: HeadingLevel.HEADING_1,
                                        alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 200,
                            before: 200
                        },
                }),




                new Paragraph({



                    children: [

                        new TextRun({
                        text: "N° : "+this.nomBon,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: "          Dépôt : "+this.nDep,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    })
                    ],

                    heading: HeadingLevel.HEADING_3,
                    alignment: AlignmentType.CENTER,

                        spacing: {
                            after: 100,
                            before: 100
                        },
                }),
                
                

                new Paragraph({



                    children: [
                        new TextRun({
                        text: "De la part de : ",
                        color: '030633',
                        bold: false,
                        font: "Segoe UI"
                    }),
                        new TextRun({
                        text: this.user_name,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    }),
                    new TextRun({
                        text: "          Date : ",
                        color: '030633',
                        bold: false,
                        font: "Segoe UI"
                    }),

                        new TextRun({
                        text: this.ddate,
                        color: '000000',
                        bold: false,
                        font: "Segoe UI"
                    })
                    ],

                    heading: HeadingLevel.HEADING_3,
                    alignment: AlignmentType.CENTER,
                        spacing: {
                            after: 300,
                            before: 100
                        },
                }),

                table
                
                ]


        }]

});










    Packer.toBlob(doc).then(blob => {

  saveAs(blob, this.nomBon+".docx");

    });

},



        fetchCategorieList: function () {
                    this.isLoading=true;
                    this.$http.get('/categories')
                    .then((result) => {
                                this.listCat = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        cat_click(id_cat)
        {
                    this.isLoading=true;
                    this.$http.get('/articlesCat/'+id_cat)
                    .then((result) => {
                                this.listArt = result.data.data

                                
                                this.isLoading=false;


                                //effacer le search:
                                this.serchArt=''
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        art_click(id_art)
        {

            let art = this.listArt.findIndex(item => item.id === id_art)
            this.itemForm.ref=this.listArt[art].ref
            this.itemForm.code_bar=this.listArt[art].code_bar
            this.itemForm.qte=1
            this.itemForm.prix_achat=this.listArt[art].prix_achat
            this.itemForm.description=this.listArt[art].description
            this.itemForm.article_id=this.listArt[art].id
            this.itemForm.unite = this.listArt[art].unite

            //si getion lot
            this.itemForm.is_lot_manag=this.listArt[art].is_lot_manag
            
            
            //rechercher le lot
            this.fetchLots(id_art)
        },
        fetchLots(id_art) {
                    this.isLoading=true;
                    this.$http.get('/lotsArtDep/'+id_art+'/'+this.idDep)
                    .then((result) => {
                                this.ListLots = result.data.data
                                this.isLoading=false;


                                if(this.ListLots.length>0)
                                {
                                        this.itemForm.lot_id=this.ListLots[0].id
                                }
                                else
                                {
                                        this.itemForm.lot_id=''
                                }
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },

        fetchListArts(id_bn)
        {
                    this.isLoading=true;
                    this.$http.get('/detailbonEntre/'+id_bn)
                    .then((result) => {
                                this.ArticlesList = result.data.data
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });


        },
        handleSubmit(){

                //set nom lot
                if(this.itemForm.lot_id)
                {
                let am = this.ListLots.findIndex(item => item.id === this.itemForm.lot_id)
                this.itemForm.nom_lot= this.ListLots[am].nom
                }



                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {

                            this.isLoading=true;

                            this.$http.post('/bnEntreDetail',{
                            ref:this.itemForm.ref,
                            description:this.itemForm.description,
                            qte:this.itemForm.qte,
                            unite:this.itemForm.unite,
                            nom_lot:this.itemForm.nom_lot,
                            lot_id:this.itemForm.lot_id,
                            article_id:this.itemForm.article_id,
                            bn_entre_id: this.idBon

                                })
                                .then(response => {

                                    this.isLoading=false;
                                    this.ArticlesList.unshift(response.data.data);

                                    //desactiver la validation:
                                    this.isSubmitted=false
                                    //afficher message
                                    this.flashMessage.show({
                                    status: 'success',
                                    title: 'Données sauvegardées',
                                    message: 'Les données sont sauvegardées correctement '
                                })


                                })
                                .catch(error=> {console.log(error)
                                this.isLoading=false;


                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur De Sauvegarde',
                                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                                })
                                });
                           

                }

        },
        del_click(itemA){

            this.isLoading=true;

            this.$http.delete('/bnEntreDetail/'+itemA.id)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })
                    let index = this.ArticlesList.findIndex(item => item.id === itemA.id)

                    this.ArticlesList.splice(index, 1)
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuEntrees'
                    });
        },
        del_BonClick() {

                                this.$confirm("Voulez-vous vraiment supprimer ce Bon??")
                                .then(() => {

                                    this.BnDell(this.idBon)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




        },
        BnDell(id_bn){

            this.isLoading=true;

            this.$http.delete('/bnEntres/'+id_bn)
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })

                //goto first page:
                    this.$router.push({ 
                        name: 'bonEntre'

                    });




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        clickValiderBon()
        {
        
                                this.$confirm("Voulez-vous vraiment VALIDER ce Bon??")
                                .then(() => {

                                    this.validerBon(this.idBon)
                                })
                                .catch(error=> {
                                console.log(error)

                                    });

        },
        validerBon(id_bn)
        {

            this.isLoading=true;

            this.$http.get('/validerEntrees/'+id_bn+'/1')
                .then(response => {
                console.log(response.data)
                this.isLoading=false;   
                this.flashMessage.show({
                status: 'success',
                title: 'Bon valider',
                message: 'Les données sont validées correctement'
                })

                //goto first page:
                    this.$router.push({ 
                        name: 'bonEntre'

                    });




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Validation',
                message: 'Impossible  de valider les données .. Réessayez !!'
                })
                });

        },
        AfficherTousArts()
        {
        this.cat_click(0)
        },
        addLot_click()
        {

            if(this.itemForm.is_lot_manag)
            {


                this.itemFormLot.nom='',
                this.itemFormLot.date =new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                this.itemFormLot.date_exp='2200-1-1',
                this.itemFormLot.prix_achat= this.itemForm.prix_achat,
                
                this.$root.$emit('bv::show::modal', 'lolmodal')
            }
            else
            {

                this.itemFormLot.nom='Par Défaut',
                this.itemFormLot.date =new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                this.itemFormLot.date_exp='2200-1-1',
                this.itemFormLot.prix_achat= this.itemForm.prix_achat,
                
                this.$root.$emit('bv::show::modal', 'lolmodal')

            }




        },
        handleSubmitLot() {


                if (this.itemFormLot.date_exp && this.itemFormLot.nom) {


                this.itemFormLot.nom,
                this.itemFormLot.qte=0,
                this.itemFormLot.date =new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                this.itemFormLot.depot_id=this.idDep,
                this.itemFormLot.article_id = this.itemForm.article_id,


                    this.isLoading=true;

                    this.$http.post('/lots',{
                   
                    nom:this.itemFormLot.nom,
                    qte:this.itemFormLot.qte,
                    date:this.itemFormLot.date,
                    date_exp:this.itemFormLot.date_exp,
                    prix_achat:this.itemFormLot.prix_achat,
                    depot_id:this.itemFormLot.depot_id,
                    article_id:this.itemFormLot.article_id,



                })
                .then(response => {

                this.isLoading=false;

               this.ListLots.unshift(response.data.data);

               this.itemForm.lot_id = response.data.data.id
                this.$bvModal.hide('lolmodal')
                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });








                }
                else 
                {


                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }

            },

            changeLot()
            {

            this.itemFormLot.nom = "Lot_"+this.itemFormLot.date_exp 
            },

        },
        components: {
                    Loading      
        }


}
</script>

<style>
/*------scroll bar---------------------*/

::-webkit-scrollbar {
  width: 5px;
  height: 7px;
}
::-webkit-scrollbar-button {
  width: 0px;
  height: 0px;
}
::-webkit-scrollbar-thumb {
  background: #9cc7f9;
  border: 0px none #ffffff;
  border-radius: 0px;
}
::-webkit-scrollbar-thumb:hover {
  background: #9cc7f9;
}
::-webkit-scrollbar-thumb:active {
  background: #9cc7f9;
}
::-webkit-scrollbar-track {
  background: transparent;
  border: 0px none #ffffff;
  border-radius: 60px;
}
::-webkit-scrollbar-track:hover {
  background: transparent;
}
::-webkit-scrollbar-track:active {
  background: transparent;
}
::-webkit-scrollbar-corner {
  background: transparent;
}

</style>
<template>
  

    <div>
    
     <b-modal  id="barcodemodal" :title="nom_article"  hide-footer>
    
    
     <div class="row d-flex justify-content-center">
    
                            <!-- Earnings (Monthly) Card Example -->
                            <div  class="pb-3">
                            <div class="card border-left-primary">
    
    
    
    
                                    <div class="d-flex justify-content-center">
                                    <div ref="barCode">
                                        <vue-barcode
                                            
                                            v-bind:value="code_barre"
                                            ref="BarImg"
                                            tag="img"
                                           
    
                                        />
                                    </div>
    
                                    </div>
                            </div>
                            </div>
    
    </div>
    
                  <div class="row">
    
    
    
               <div class="col">
           
    
                <div class="row">
              
                            <div class="form-group col">
                                <label for="Prix">Prix :</label>
                                <input type="text" v-model="prix" id="Prix" name="Prix" class="form-control"
                                    :class="{ 'is-invalid': isSubmitted && $v.prix.$error }" />
                                <div v-if="isSubmitted && !$v.prix.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                <div v-if="isSubmitted && !$v.prix.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                            </div>


                            <div class="form-group col">
                                <label for="nmrbrTicket">Nombre De Ticket :</label>
                                <input type="text" v-model="nmrbrTicket" id="nmrbrTicket" name="nmrbrTicket" class="form-control"
                                    :class="{ 'is-invalid': isSubmitted && $v.nmrbrTicket.$error }" />
                                <div v-if="isSubmitted && !$v.nmrbrTicket.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
                                <div v-if="isSubmitted && !$v.nmrbrTicket.decimal" class="invalid-feedback">Entrez une valeur numérique !!</div>
                            </div>
                </div>
    

                            
            <div class="row">
                <div class="form-group col">
                                <button class="btn  btn-secondary btn-block" @click="handleSubmitLot(1)">Générer (Grande Taille)</button>
                            </div>

                            <div class="form-group col">
                                <button class="btn  btn-info btn-block" @click="handleSubmitLot(0)">Générer (Petite Taille)</button>
                            </div>
            </div>
    
    
    

  
    
                            </div>
                  </div>
    
    
    
    
    
    </b-modal>
    
    
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
    </div>
    
    </template>
    
    <script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {required,decimal} from "vuelidate/lib/validators";
    import VueBarcode from 'vue-barcode';
    import html2canvas from "html2canvas"; 
    import jsPDF from 'jspdf';
    
    export default {
     props: ['nom_article','code_barre','prix'],
    
          computed: {
    
    
          total_price: function() {
          return this.LotsList.reduce((a, b) => +a + +b.qte, 0);
        }
      },
        data () {
            return {
                
    
                nmrbrTicket: 1,
    
                isSubmitted: false,
                isLoading: false,
    
            }
        },
        validations: {
                nmrbrTicket: {
                        required,
                        decimal
                },
                prix: {
                        required,
                        decimal
                },

        },
        methods :{
    
            handleSubmitLot(typ) {
    
                    this.isSubmitted = true;
    
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                    this.flashMessage.show({
                    status: 'info',
                    title: 'Vérifiez les données et Reésayez !!',
                    })
                    }
                    else 
                    {
                        if(typ==1)
                        {
                         this.print_pdf();
                        }
                        else
                        {
                            this.print_pdf_sm();
                        }

                        
              
                    }
            },
    
    
            print_pdf()
            {
                const doc = new jsPDF(
                    {
                    orientation: "landscape",
                    unit: "mm",
                    format: [70, 50]
                    }
                );
    
                if(this.nmrbrTicket>0)
                {
    
    
                            html2canvas(this.$refs.barCode).then((canvas) => {
                               const img = canvas.toDataURL("image/png");
    
                                for (let i = 0; i < this.nmrbrTicket; i++) {
    
                                    doc.setFontSize(17);
                                   


                                    doc.setFontSize(13);
                                        doc.text(this.nom_article, 35, 9,{ align: 'center' });
                                    doc.text("Prix : "+this.prix+" DH", 35, 15,{ align: 'center' });
    


                                    doc.addImage(img, "PNG", 5, 17, 60, 28);   
                                    
                   
    
                                    
                                    doc.addPage();
                                }
                                
                                doc.save("Code_Barre_G"+this.nom_article+".pdf");
                            })
    
    
                }
                else
                {
                                this.flashMessage.show({
                                status: 'info',
                                title: 'Vérifiez le nombre de tickets !!',
                                })
                }
    
    
            },
    
    
        
            print_pdf_sm()
            {
                const doc = new jsPDF(
                    {
                    orientation: "landscape",
                    unit: "mm",
                    format: [50, 25]
                    }
                );
    
                if(this.nmrbrTicket>0)
                {
    
    
                            html2canvas(this.$refs.barCode).then((canvas) => {
                               const img = canvas.toDataURL("image/png");
    
                                for (let i = 0; i < this.nmrbrTicket; i++) {
    



                                    doc.setFontSize(11);
                                        doc.text(this.nom_article, 25, 5,{ align: 'center' });

    


                                    doc.addImage(img, "PNG", 3, 6, 44, 17);   
                                    
                   
    
                                    
                                    doc.addPage();
                                }
                                
                                doc.save("Code_Barre_P"+this.nom_article+".pdf");
                            })
    
    
                }
                else
                {
                                this.flashMessage.show({
                                status: 'info',
                                title: 'Vérifiez le nombre de tickets !!',
                                })
                }
    
    
            },

            
        }
    
        ,
        components: {
                  Loading,
    
                  "vue-barcode": VueBarcode,
                 
        }
    }
    </script>
    
    <style>
    
    </style>
<template>
    <div>
      
                <div class="rounded-lg shadow border border-white bg-white mb-4">
                            <div class="row justify-content-centre align-items-center">    
    
                  <div class="col">
      
    
    
    
    
                    <div class="input-group  border border-white rounded bg-white p-2  justify-content-centre align-items-center text-info">
    
                                    <label for="depot_id" class="mr-sm-2 pt-2"> Dépôt :</label>
    
                                                <select class="form-control border rounded mr-2" id="depot_id" v-model="itemFormBon.depot_id"
                                                :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.depot_id.$error }">
    
                                                <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                                    {{ item.nom }}
                                                </option>
    
                                                <option v-if="user_dep==0" value=0>Tous</option>
    
                                                </select>


                                                <label for="depot_id" class="mr-sm-2 pt-2"> Catégorie :</label>

                                                <select class="form-control border border-ligth " id="selected_centre" v-model="itemFormBon.cat_id"
                                                :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.cat_id.$error }">
                                                    <option v-for="item in categories" :key="item.id" v-bind:value="item.id">   
                                                        {{ item.nom }}
                                                    </option>
                                                    <option value=0>Tous</option>

                                                </select>
                                    
    
                                        <div class="input-group-prepend ml-2">
                                            <button class="btn  btn-outline-info rounded-pill ml-1" type="submit" id="button-addon1" @click="handleSubmitBonCMD()">   
                                            <span class="fa fa-search"></span> Liste
                                            </button>
                                        </div>
    
    
    
                    </div>
    
    
    
    
    
    
                </div>
                            </div>          
                </div>  
    
    
    
     
          <div class="rounded-lg shadow border border-white bg-white">
                <div class="row justify-content-centre align-items-center">    
    
                <h4 class="col ml-3 mt-1 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Valorisation Du Stock</h4> 
    
                <h5  class="col m-3 font-weight-normal text-primary">  Total <span v-if="is_cat==1" class="text-danger"> ({{ itemFormBon.cat_nom }})</span> : {{t_valorisation}} DH</h5> 
    
                        <div class="col-2">
        
    
                                        <b-button @click="exportDox" pill size="sm"  class="m-3 float-right" variant="info">
                                            <i class="fa fa-print"></i>
                                            <span> Rapport</span> 
                                        </b-button>
    
                        </div>
                </div>
           
    
    
    
            <b-table 
    
                    id="main-table"
            :per-page="perPage"
            :current-page="currentPage"
    
            sort-icon-left
            head-variant="light"
    
            :fields="fields" 
            :items="itemslist"
            >
          
            </b-table>
    
                    <b-pagination
                    pills
                    v-model="currentPage"
                    :total-rows="rows_pagin"
                    :per-page="perPage"
                    aria-controls="main-table"
                    align="center"
                    ></b-pagination>
    
          </div>
    
    
    
    
    
        <!-- les trucs public -->
        <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>
    
    
    </div>
</template>
    
    <script>
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/vue-loading.css';
    import {required} from "vuelidate/lib/validators";
    import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
    import { saveAs } from 'file-saver';
    
    export default {
          computed: {
                  entet_pag() {
    
          if(this.$store.getters.get_entete)
          {
                return this.$store.getters.get_entete;
          }
          else
          {
                return '';
          }
        },
        sousentet_pag() {
    
          if(this.$store.getters.get_sousentete)
          {
                return this.$store.getters.get_sousentete;
          }
          else
          {
                return '';
          }
        },
        pied_pag() {
    
          if(this.$store.getters.get_pied)
          {
                return this.$store.getters.get_pied;
          }
          else
          {
                return '';
          }
        },
                    rows_pagin() {
            return this.itemslist.length
          },
        user_dep() {
    
          if(this.$store.getters.get_user)
          {
                return this.$store.getters.get_user.dep;
          }
          else
          {
                return 0;
          }
        },

        t_valorisation: function() {
          return this.itemslist.reduce((a, b) => +a + +b.total_prix, 0);
        },


    
        },
        mounted(){
          this.fetchCentre()
        },
        data () {
            return {
                perPage: 10,
                currentPage: 1,
                is_cat:0,
                itemFormBon: {
                        depot_id:'',
                        cat_id:'',
                        cat_nom:''
                },
                categories:[],
                depots:[],
                itemslist: [],
                fields: [
                    {key: 'ref', label: 'Référence', sortable: true},
                    {key: 'total_qte', label: 'Total Quantité', sortable: true},
                    {key: 'total_prix', label: 'Valorisation', sortable: true},
                ],
    
                isSubmitted: false,
                isLoading: false
            }
        },
        validations: {
                itemFormBon: {
                    depot_id: {
                        required
                    },
                    cat_id: {
                        required
                    }
    
                }
    
        },
        methods :{
    
    
        exportDox()
                {
        
                let cent = ""

        
        
                if(this.itemFormBon.depot_id==0)
                {
                cent="Tous"
                }
                else
                {
                let mm=this.depots.findIndex(item => item.id === this.itemFormBon.depot_id)
                cent= this.depots[mm].nom
                }
        


        
        
                let datrw =[new TableRow({
                            children: [
                                new TableCell({
                                    width: {
                                        size: 5000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: "Référence",
                                                    spacing: {
                                                    before: 100,
                                                    after: 100
                                                },
                                                heading: HeadingLevel.HEADING_4,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
        
        
                                new TableCell({
                                    width: {
                                        size: 5000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: "Total Quantité",
                                                heading: HeadingLevel.HEADING_4,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
                                new TableCell({
                                    width: {
                                        size: 8000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: "Valorisation",
                                                heading: HeadingLevel.HEADING_4,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

        
                            ],
                        })]
        
                this.itemslist.forEach(function(item){
        
                let r = new TableRow({
                            children: [
                                new TableCell({
                                    width: {
                                        size: 5000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: item.ref,
                                                spacing: {
                                                before: 50,
                                                after: 50
                                            },
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
        
        
                                new TableCell({
                                    width: {
                                        size: 5000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: item.total_qte.toString(),
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
        

        
        
                                new TableCell({
                                    width: {
                                        size: 8000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: item.total_prix.toString(),
                                                heading: HeadingLevel.HEADING_6,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
                                

        
                            ],
                        })
        
                datrw.push(
                r
                )
                });
        
        
        
                const table = new Table({
        
                    rows:  datrw
                })
        
        const doc = new Document({
        
            sections: [
                {
        
        
                            properties: {
                                page: {
                                    pageNumbers: {
                                        start: 1,
                                        formatType: PageNumberFormat.DECIMAL,
                                    },
                                },
                            },
                            headers: {
                                default: new Header({
                                    children: [
                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.entet_pag,
                                        color: '636572',
                                        bold: true,
        
                                        }),
                                            ],
                                            heading: HeadingLevel.TITLE,
                                            alignment: AlignmentType.CENTER,
                                        }),
        
                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.sousentet_pag,
                                        color: '636572',
                                        bold: false,
                                        })
        
                                            ],
                                        border: {
                                        bottom: {
                                                color: "636572",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        }),
        
        
        
        
                                    ],
                                }),
                            },
                            footers: {
                                default: new Footer({
                                    children: [
        
                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.pied_pag,
                                        color: '636572',
                                        bold: false,
                                        })
        
                                            ],
                                        border: {
                                        top: {
                                                color: "636572",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        }),
        
                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    children: ["Page : ", PageNumber.CURRENT],
                                                    color: '636572'
                                                }),
                                                new TextRun({
                                                    children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                    color: '636572'
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                            },
        
        
        
        
                            children: [
        
                                new Paragraph({
        
        
        
                                    children: [
        
                                        new TextRun({
                                        text: " VALORISATION DU STOCK",
                                        color: '636572',
                                        bold: true,
                                        font: "Segoe UI Light"
                                    }),
    
                                    ],
        
                                    heading: HeadingLevel.HEADING_1,
                                                        alignment: AlignmentType.CENTER,
        
                                        spacing: {
                                            after: 300,
                                            before: 300
                                        },
                                }),

                                new Paragraph({
        
        
        
        children: [


            new TextRun({
            text: "     Dépôt : "+cent,
            color: '636572',
            bold: true,
            font: "Segoe UI Light"
        }),

        new TextRun({
            text: "             "+this.itemFormBon.cat_nom,
            color: '636572',
            bold: true,
            font: "Segoe UI Light"
        }),

    new TextRun({
        text: "        Total : "+this.t_valorisation,
        color: '636572',
        bold: true,
        font: "Segoe UI Light"
    })

        ],

        heading: HeadingLevel.HEADING_2,
                            alignment: AlignmentType.CENTER,

            spacing: {
                after: 300,
                before: 300
            },
    }),
                                
                                
                                table
                                
                                ]
        
                }]
        
        });
        
        
        
        
                    Packer.toBlob(doc).then(blob => {
        
                saveAs(blob, "valorisation.docx");
        
                    });
        
                },
        
            
        fetchCentre: function () {
    
                            //chager les categories:
                            this.isLoading=true;
                    this.$http.get('/categories')
                    .then((result) => {
                                this.categories = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
    
                        //chager les depots:
                        this.isLoading=true;
                        this.$http.get('/depotPerson/'+this.user_dep)
                        .then((result) => {
                                    this.depots = result.data.data
                                    this.isLoading=false;
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });
    
            },
    
                    handleSubmitBonCMD(){
    
                    this.isSubmitted = true;
    
                    this.$v.$touch();
                    if (this.$v.$invalid) {
                    this.flashMessage.show({
                    status: 'info',
                    title: 'Vérifiez les données et Reésayez !!',
                    })
                    }
                    else 
                    {
    
    
                    this.fetchList(this.itemFormBon.depot_id,this.itemFormBon.cat_id)
       
    
                    }
    
    
            },
    
    
            fetchList: function (iddep,idcat) {
                        this.is_cat=0
                
                        this.isLoading=true;
                        
                        this.$http.get('/valorisationStock/'+iddep+'/'+idcat)
                        .then((result) => {
                                    this.itemslist = result.data.data
                                    this.isLoading=false;


                                    if(idcat>0)
                                    {
                                        this.is_cat=1
                                        let mm=this.categories.findIndex(item => item.id === this.itemFormBon.cat_id)
                                        this.itemFormBon.cat_nom=this.categories[mm].nom
                                    }
                                    else
                                    {
                                        this.itemFormBon.cat_nom=""
                                    }
                        })
                        .catch(error=> {
                                    console.log(error)
                                    this.isLoading=false;
                                    this.flashMessage.show({
                                    status: 'error',
                                    title: 'Erreur de Chargement',
                                    message: 'Impossible charger les données.. Actualisez et Réessayez'
                                    })
                        });
            }
    
        },
        components: {
                  Loading      
        }
    }
    </script>
    
    <style  scoped>
    
    </style>